import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
const KendoPage = ({ data }) => (
  <Layout>
    <SEO title="Kendo" />

    <div style={{ maxWidth: `1200px`, marginBottom: `1.45rem` }}>
      <h1>LE KENDO : LA VOIE DU SABRE</h1>
      <h2>Principe:</h2>
      <p>Le Kendo est l'art martial d'escrime orientale. Il s'agit du maniement du Katana, le sabre japonais. Cet art se pratique avec des coups portés lors d'assauts réels entre deux pratiquants. De ce fait les combattants utilisent des Shinaïs, des sabres de bambous, et portent une armure traditionnelle nommée Bogu. </p>
      <p>Le combattant doit alors exécuter des frappes spécifiques et démontrer sa puissance avec un Kiai, sorte de cri poussé lors de l'attaque. </p>
      <p>Afin de manier au mieux le Katana, le Kendo s'enseigne sous forme de Kyons (exercices) et de Katas (techniques codifiées au sabre de bois plein), techniques qui seront mise en oeuvre lors des combats d'entraînement (Keiko). </p>

      <h2>Pratique:</h2>
      <p>Le Kendo se pratique dans un dojo, sur un parquet. Pour débuter le novice n'a besoin que d'une tenue de sport légère. Les armes d'entraînement lui seront prêtées lors des premières séances ou jusqu'à l'achat par le pratiquant. Il en va de même pour les pièces d'armure qui lui seront prêtées au fur et à mesure de son évolution sportive. </p>
      <p>De plus les combats réels ne se font qu'après un certain temps de pratique afin d'assurer la sécurité du pratiquant ainsi que de son adversaire. </p>
      <p>A noter que le Kendo possède une forte dimension sportive. Si le KICN prend part à un certain nombre de compétitions, la participation ne se fait que sur la base du volontariat et n'influe en rien sur les entraînements semainiers au club. </p>

      <h2>Note Historique:</h2>
      <p>Les techniques de sabre du Kenjutsu remontent aux premières guerres des samouraïs. Elles constituent l'essence même de la pratique du sabre et sont utilisées dans le cadre de la guerre. </p>
      <p>Cependant durant la restauration Meiji de 1863 la pratique du sabre fut grandement diminuée et perdit sa dimension martiale au profit de la guerre moderne. On y attribua alors une dimension philosophique et spirituelle, et le Kenjutsu devint le Kendo. </p>
      <p>Après la Second Guerre Mondiale le Kendo prit une grande dimension sportive en plus de l'art martial. On commença alors à établir des règles strictes pour une pratique sécurisée. En 1970 naquit la Fédération Internationale de Kendo, qui permet de faire rayonner l'art martial à travers le monde. </p>
    </div>

  </Layout>
)

export default KendoPage
